/*
 * For security reasons, certain browsers (primarily mobile ones) will not allow file inputs to be \
 * triggered remotely by jQuery. In an earlier life, we used a single button, which, when clicked,
 * would trigger addition of the nested form AND trigger a click event on the file input contained
 * within the nested form. At some point we tested that on mobile and it wasn't working.
 *
 * Now, when the document is loaded, we will automatically trigger addition of the first nested form,
 * which will expose a styled version of a file input to users, which they can click directly. Then,
 * we listen on change to the file input and update the nested forms accordingly so the tag representing
 * the added file appears, as well as trigger the next nested form addition. Works like a charm.
 */

import $ from "jquery";

import idempotence from "../../idempotence";
import nodeListToArray from "../../nodeListToArray";
import ready from "../../ready";

const nestedFileUploader = {
  init() {
    nodeListToArray(
      document.querySelectorAll("[data-js-nested-attachments]")
    ).forEach((container) => {
      this.initContainer(container);
    });
  },

  initContainer(container) {
    if (idempotence.guard(container, "nested-field-added")) return;

    // ugh nested form requires jquery
    $(container).on("nested:fieldAdded", (e) => {
      const field = e.field[0];
      const spacer = document.createElement("div");
      spacer.classList.add("mb-2", "w-100");
      container.insertBefore(spacer, field);
      const tag = field.querySelector(".tag");
      if (tag) tag.classList.add("d-none");
      const fileInput = field.querySelector(`input[type="file"]`);
      if (fileInput) {
        fileInput.addEventListener("change", (innerEvent) => {
          const file = innerEvent.target.files[0];
          if (file !== undefined) {
            container.removeChild(spacer);
            if (tag) {
              tag.classList.remove("d-none");
              const value = tag.querySelector(".value");
              if (value) value.textContent = file.name;
            }
            const buttonLabel = field.querySelector(".btn");
            if (buttonLabel) {
              buttonLabel.classList.add("d-none");
            }

            // trigger another nested field to expose a new file input to the user
            const addButton = container.parentNode.querySelector(
              "[data-js-add-nested-attachment]"
            );
            if (addButton) addButton.click();
          } else {
            alert("File selection failed. Please try again.");
          }
        });
      }
    });
  },

  exposeFileInput(guard) {
    nodeListToArray(
      document.querySelectorAll("[data-js-add-nested-attachment]")
    ).forEach((button) => {
      if (guard && idempotence.guard(button, "clicked-once")) return;
      button.click();
    });
  },
};

ready(() => {
  nestedFileUploader.init();
  nestedFileUploader.exposeFileInput(true);
});

export default nestedFileUploader;
