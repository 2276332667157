import idempotence from "../idempotence";
import nodeListToArray from "../nodeListToArray";
import ready from "../ready";

const previewImage = {
  init() {
    nodeListToArray(
      document.querySelectorAll(`[data-js-toggle="preview-image"]`)
    ).forEach((preview) => {
      this.initItem(preview);
    });
  },

  initItem(preview) {
    if (idempotence.guard(preview, "preview-image")) return;

    const input = preview.querySelector(`input[type="file"]`);
    input.addEventListener("change", (e) => {
      const file = e.currentTarget.files[0];
      let image = preview.querySelector(".preview-image img");
      if (!image) {
        image = document.createElement("img");
        image.classList.add("mb-2");
        // only apply the rounding if there's already a rounded placeholder
        if (preview.querySelector(".preview-image .rounded-circle")) {
          image.classList.add("rounded-circle");
        }
        image.setAttribute("height", "100");
        preview.querySelector(".preview-image").innerHTML = "";
        preview.querySelector(".preview-image").appendChild(image);
      }

      if (window.FileReader) {
        const reader = new FileReader();
        reader.onloadend = (innerEvent) => {
          image.setAttribute("src", innerEvent.currentTarget.result);
          image.classList.remove("d-none");
          image.removeAttribute("width");
        };
        if (file && file.type.match("image.*")) {
          reader.readAsDataURL(file);
        }
      }
    });
  },
};

ready(() => {
  previewImage.init();
});

export default previewImage;
