import { json, status } from "../fetchUtils";
import idempotence from "../idempotence";
import nodeListToArray from "../nodeListToArray";
import ready from "../ready";

const unreadBadge = {
  init() {
    const navTabWrappers = nodeListToArray(
      document.querySelectorAll("[data-js-show-badge-for]")
    );
    if (navTabWrappers.length > 0) {
      const navTabsSection = navTabWrappers[0].parentElement;
      if (idempotence.guard(navTabsSection, "nav-badges")) return;

      const params = navTabWrappers
        .map(
          (element) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            `types[]=${element.getAttribute("data-js-show-badge-for")}`
        )
        .join("&");

      fetch(`/nav/unread_counts?${params}`, { method: "GET" })
        .then(status)
        .then(json)
        .then((response) => {
          navTabWrappers.forEach((element) => {
            const badge = element.querySelector(".badge");
            const key = element.getAttribute("data-js-show-badge-for");
            let count = response[key];
            if (count > 0) {
              if (count > 9) {
                count = "9+";
              }
              badge.querySelector(".count").textContent += count;
              badge.classList.remove("invisible");
            }
          });
        });
    }
  },
};

ready(() => {
  unreadBadge.init();
});

export default unreadBadge;
