import { Popover } from "bootstrap";

import { json, status } from "../fetchUtils";
import executeScript from "../executeScript";
import idempotence from "../idempotence";
import nodeListToArray from "../nodeListToArray";
import ready from "../ready";
import redirect from "../redirect";

import authenticityToken from "./forms/authenticityToken";

window.fbAsyncInit = () => {
  FB.init({
    appId: import.meta.env.VITE_FACEBOOK_APP_ID,
    cookie: true, // enable cookies to allow the server to access the session
    version: import.meta.env.VITE_FACEBOOK_API_VERSION,
    xfbml: true, // parse social plugins on this page
  });
};

// Load the SDK asynchronously
((d, s, id) => {
  if (d.getElementById(id)) return;
  const fjs = d.getElementsByTagName(s)[0];
  const js = d.createElement(s);
  js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
})(document, "script", "facebook-jssdk");

const facebook = {
  init() {
    nodeListToArray(document.querySelectorAll("[data-fb-login-url]")).forEach(
      (button) => {
        this.initButton(button);
      }
    );
  },

  callFacebookLogin({ error, success }) {
    if (import.meta.env.MODE === "test") {
      success();
    } else {
      FB.login(
        (response) => {
          if (response.authResponse) {
            success();
          } else {
            // Cancelled or otherwise not fully authorized
            error();
          }
        },
        { scope: "email,public_profile,user_hometown,user_location" }
      );
    }
  },

  initButton(button) {
    if (idempotence.guard(button, "facebook-init")) return;

    button.removeAttribute("disabled");
    const maybeAForm = button.parentNode;
    if (maybeAForm && maybeAForm.tagName === "FORM") {
      maybeAForm.addEventListener("submit", (e) => {
        e.preventDefault();
      });
    }

    button.addEventListener("click", (e) => {
      e.preventDefault();
      if (typeof FB === "object" && typeof FB.login === "function") {
        const popover = Popover.getInstance(button);
        if (popover) popover.dispose();
        idempotence.remove(button, "facebook-popovered");
        const url = new URL(button.dataset.fbLoginUrl);
        const token = authenticityToken();
        // specs keep failing unless we do this one via search params
        if (token) url.searchParams.set("authenticity_token", token);

        this.callFacebookLogin({
          error: () => {
            button.innerHTML = button.dataset.disableWithOriginalValue;
          },
          success: () => {
            fetch(url.toString(), {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              method: button.dataset.fbLoginMethod?.toUpperCase() ?? "GET",
            })
              .then(status)
              .then(json)
              .then((response) => {
                redirect.to(response.go);
              })
              .catch((error) => {
                error.json().then((response) => {
                  if (response && response.execute) {
                    executeScript.execute(response.execute);
                  }
                });
              });
          },
        });
      } else if (!idempotence.guard(button, "facebook-popovered")) {
        // eslint-disable-next-line operator-linebreak
        const facebookWarning =
          // eslint-disable-next-line operator-linebreak
          "Facebook's login tools failed to load, which probably means your " +
          // eslint-disable-next-line operator-linebreak
          "browser has Javascript disabled or a blocker tool that prevents it from loading. In " +
          "order to use your Facebook account, consider using a different browser.";
        const popover = new Popover(button, {
          content: facebookWarning,
          placement: "auto",
          trigger: "manual",
        });
        popover.show();
      } else {
        const popover = Popover.getInstance(button);
        if (popover) popover.toggle();
      }
    });
  },
};

ready(() => {
  facebook.init();
});

export default facebook;
