import authenticityToken from "./authenticityToken";

import executeScript from "../../executeScript";
import { json, status } from "../../fetchUtils";
import idempotence from "../../idempotence";
import nodeListToArray from "../../nodeListToArray";
import ready from "../../ready";

const checkBoxTrigger = {
  init() {
    nodeListToArray(
      document.querySelectorAll("[data-js-check-box-trigger-path]")
    ).forEach((checkbox) => {
      this.initItem(checkbox);
    });
  },

  initItem(checkbox) {
    if (idempotence.guard(checkbox, "checkBoxTrigger")) return;

    const path = checkbox.dataset.jsCheckBoxTriggerPath;
    const method = checkbox.dataset.jsCheckBoxTriggerMethod;
    const field = checkbox.dataset.jsCheckBoxTriggerField;

    checkbox.addEventListener("change", () => {
      const data = new FormData();
      data.append(field, checkbox.checked ? "1" : "0");
      data.append("_method", method);
      const token = authenticityToken();
      if (token) data.append("authenticity_token", token);

      fetch(path, {
        body: data,
        headers: { Accept: "application/json" },
        method: "POST",
      })
        .then(status)
        .then(json)
        .then((response) => {
          executeScript.execute(response.execute);
        });
    });
  },
};

ready(() => {
  checkBoxTrigger.init();
});

export default checkBoxTrigger;
