import idempotence from "../idempotence";
import nodeListToArray from "../nodeListToArray";
import ready from "../ready";

const collapse = {
  init() {
    nodeListToArray(document.querySelectorAll(".collapse")).forEach(
      (collapser) => {
        if (!idempotence.guard(collapser, "collapse")) {
          if (collapser.dataset.jsNofocus !== "true") {
            collapser.addEventListener("shown.bs.collapse", () => {
              const input = collapser.querySelector(
                `input:not([type="hidden"]`
              );
              if (input) {
                input.focus();
              }
            });
          }
        }
      }
    );
  },
};

ready(() => {
  collapse.init();
});

export default collapse;
