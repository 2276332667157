import authenticityToken from "./authenticityToken";

import executeScript from "../../executeScript";
import { json, status } from "../../fetchUtils";
import idempotence from "../../idempotence";
import nodeListToArray from "../../nodeListToArray";
import ready from "../../ready";
import redirect from "../../redirect";

const fakeForm = {
  init() {
    const self = this;
    nodeListToArray(document.querySelectorAll("[data-js-fake-form]")).forEach(
      (form) => {
        const submitButton = form.querySelector("[data-js-fake-form-submit]");
        if (submitButton) {
          if (!idempotence.guard(submitButton, "fake-form")) {
            submitButton.addEventListener("click", (e) => {
              e.preventDefault();
              self.submit(form, submitButton);
            });
            // provide form-like functionality by submitting when the user hits "enter"
            form.addEventListener("keypress", (e) => {
              if (e.keyCode === 13) {
                self.submit(form, submitButton);
              }
            });
          }
        }
      }
    );
  },

  submit(form, submitButton) {
    const self = this;

    self.toggleProcessingMessage(submitButton);
    fetch(form.dataset.jsFakeFormAction, {
      body: this.getData(form),
      headers: { Accept: "application/json" },
      method: form.dataset.jsFakeFormMethod,
    })
      .then(status)
      .then(json)
      .then((response) => {
        self.toggleProcessingMessage(submitButton);
        if (response.redirect_to) {
          redirect.to(response.redirect_to);
        } else if (response.execute) {
          executeScript.execute(response.execute);
        }
      })
      .catch((error) => {
        error.json().then((response) => {
          self.toggleProcessingMessage(submitButton);
          if (response.message) {
            alert(response.message);
          } else if (response.execute) {
            executeScript.execute(response.execute);
          }
        });
      });
  },

  getData(form) {
    const data = new FormData();
    const token = authenticityToken();
    if (token) data.append("authenticity_token", token);
    nodeListToArray(form.querySelectorAll("[data-js-fake-form-field]")).forEach(
      (field) => {
        if (field.files) {
          data.append(field.getAttribute("name"), field.files[0]);
        } else {
          data.append(field.getAttribute("name"), field.value);
        }
      }
    );
    return data;
  },

  toggleProcessingMessage(button) {
    const currentMessage = button.dataset.jsFakeFormProcessingMessage;
    button.setAttribute(
      "data-js-fake-form-processing-message",
      button.innerHTML
    );
    button.innerHTML = currentMessage;
  },
};

ready(() => {
  fakeForm.init();
});

export default fakeForm;
