import { Popover } from "bootstrap";
import nodeListToArray from "../nodeListToArray";
import ready from "../ready";

// hide popovers when the user clicks away from them
const popover = {
  init() {
    document.addEventListener("click", (e) => {
      if (e.target.dataset.bsToggle !== "popover") {
        nodeListToArray(
          document.querySelectorAll(`[data-bs-toggle="popover"]`)
        ).forEach((element) => {
          new Popover(element).hide();
        });
      }
    });
  },
};

ready(() => {
  popover.init();
});

export default popover;
