import idempotence from "../../idempotence";
import nodeListToArray from "../../nodeListToArray";
import ready from "../../ready";

const characterCounter = {
  init() {
    nodeListToArray(document.querySelectorAll("[data-js-char-limit]")).forEach(
      (counter) => {
        this.initItem(counter);
      }
    );
  },

  initItem(input) {
    if (idempotence.guard(input, "character-counter")) return;

    const self = this;
    ["change", "keyup", "paste", "cut"].forEach((eventName) => {
      input.addEventListener(eventName, () => {
        self.update(input);
      });
    });
    input.addEventListener("focus", () => {
      self.label(input).classList.remove("d-none");
      self.update(input);
    });
    input.addEventListener("focusout", () => {
      self.label(input).classList.add("d-none");
    });
  },

  update(input) {
    const limit = input.dataset.jsCharLimit;
    let remaining = limit - input.value.length;
    const label = this.label(input);

    if (remaining < 0) {
      input.value = input.value.slice(0, limit);
      remaining = 0;
    } else if (remaining < 10) {
      label.classList.add("danger");
    } else {
      label.classList.remove("danger");
    }
    label.textContent = remaining;
  },

  hide(input) {
    this.label(input).classList.add("d-none");
  },

  label(input) {
    return input.parentNode.querySelector("[data-js-char-limit-label]");
  },
};

ready(() => {
  characterCounter.init();
});

export default characterCounter;
