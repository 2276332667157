import { createPopup } from "@picmo/popup-picker";

import idempotence from "../../idempotence";
import nodeListToArray from "../../nodeListToArray";
import ready from "../../ready";

const addEmojiButton = {
  init() {
    nodeListToArray(
      document.querySelectorAll(`[data-js-perform="add-emoji"]`)
    ).forEach((button) => {
      this.initItem(button);
    });
  },

  initItem(button) {
    if (idempotence.guard(button, "add-emoji-button")) return;

    const input = document.getElementById(button.dataset.jsTarget);

    const picker = createPopup(
      {
        autoFocusSearch: false,
      },
      {
        className: "picmo-popup",
        referenceElement: button,
        triggerElement: button,
      }
    );

    picker.addEventListener("emoji:select", (selection) => {
      input.value += selection.emoji;
      input.focus();
    });

    button.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      picker.toggle();
      input.focus();
    });
  },
};

ready(() => {
  addEmojiButton.init();
});

export default addEmojiButton;
