import { update } from "jdenticon";

import idempotence from "./idempotence";
import nodeListToArray from "./nodeListToArray";
import ready from "./ready";

const jdenticon = () => {
  nodeListToArray(document.querySelectorAll("[data-jdenticon-value]")).forEach(
    (icon) => {
      if (!idempotence.guard(icon, "jdenticonized")) {
        update(icon, icon.dataset.jdenticonValue);
      }
    }
  );
};

// eslint-disable-next-line camelcase
window.jdenticon_config = {
  replaceMode: "observe",
};

window.jdenticon = jdenticon;

ready(() => {
  jdenticon();
});
