// extends boostraps dropdown by expanding it on hover
import { Dropdown } from "bootstrap";

import idempotence from "../idempotence";
import nodeListToArray from "../nodeListToArray";
import ready from "../ready";

const hoverDropdown = {
  init: () => {
    nodeListToArray(
      document.querySelectorAll("[data-js-hover-dropdown]")
    ).forEach((item) => {
      if (!idempotence.guard(item, "hover-dropdown")) {
        const toggle = item.querySelector(".dropdown-toggle");
        if (toggle) {
          const dropdown = Dropdown.getInstance(toggle) ?? new Dropdown(toggle);
          toggle.addEventListener("click", (e) => {
            e.preventDefault();
          });
          item.addEventListener("mouseover", () => {
            dropdown.show();
          });
          item.addEventListener("mouseout", () => {
            dropdown.hide();
          });
        }
      }
    });
  },
};

ready(() => {
  hoverDropdown.init();
});

export default hoverDropdown;
