import { json, status } from "../../fetchUtils";
import idempotence from "../../idempotence";
import nodeListToArray from "../../nodeListToArray";
import ready from "../../ready";

const memberMapComponent = {
  init() {
    nodeListToArray(
      document.querySelectorAll("[data-js-load-member-map]")
    ).forEach((loader) => {
      if (!idempotence.guard(loader, "load-member-map")) {
        const url = new URL(window.location.href);
        url.pathname = "/members/load_map_component";
        fetch(url.toString(), { method: "GET" })
          .then(status)
          .then(json)
          .then((response) => {
            loader.innerHTML = response.component;
            window.Alumnifire.memberMap.init();
          });
      }
    });
  },
};

ready(() => {
  memberMapComponent.init();
});

export default memberMapComponent;
