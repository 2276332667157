import idempotence from "../../idempotence";
import nodeListToArray from "../../nodeListToArray";
import ready from "../../ready";

const eventTime = {
  init() {
    nodeListToArray(
      document.querySelectorAll(`[data-js-toggle="event-time"]`)
    ).forEach((fieldset) => {
      this.initFieldset(fieldset);
    });
  },

  initFieldset(fieldset) {
    const self = this;
    if (idempotence.guard(fieldset, "event-time")) return;

    const timeLabel = fieldset.querySelector(`[data-bs-toggle="collapse"]`);
    const endTime = self.endTime(fieldset);
    const endTimeToggle = endTime.querySelector(`[data-bs-toggle="collapse"]`);
    const removeEndTime = endTime.querySelector("[data-js-remove-end-time]");
    const endHourOptions = nodeListToArray(
      self.endHourField().getElementsByTagName("option")
    );

    const timeLabelSuffix = document.createElement("span");
    timeLabel.appendChild(timeLabelSuffix);
    // self.updateTimeLabel(fieldset, timeLabelSuffix);

    timeLabel.addEventListener("click", () => {
      self.updateTimeLabel(fieldset, timeLabelSuffix);
    });

    endTimeToggle.addEventListener("click", () => {
      if (!endTimeToggle.dataset.everBeenOpened) {
        endTimeToggle.dataset.everBeenOpened = true;
        // Default the end time to an hour later than the start time
        const startHourOptions = nodeListToArray(
          self.startHourField().getElementsByTagName("option")
        );
        const selectedStartOptions = startHourOptions.filter(
          (option) => option.selected
        );
        const selectedStartIdx = startHourOptions.indexOf(
          selectedStartOptions[0]
        );

        // Default the end date to the same value as the start date unless it's less than an hour from midnight
        if (selectedStartIdx + 5 > endHourOptions.length) {
          endHourOptions[
            selectedStartIdx + 5 - endHourOptions.length
          ].selected = true;
          const nextDay = new Date(self.startDateField().value + " (UTC)");
          nextDay.setDate(nextDay.getDate() + 1);
          const addLeadingZeroes = (n) => (n > 9 ? n : `0${n}`);
          self.endDateField().value = `${nextDay.getFullYear()}-${addLeadingZeroes(
            nextDay.getMonth() + 1
          )}-${addLeadingZeroes(nextDay.getDate())}`;
        } else {
          endHourOptions[selectedStartIdx + 4].selected = true;
          self.endDateField().value = self.startDateField().value;
        }
      }
    });

    removeEndTime.addEventListener("click", (e) => {
      e.preventDefault();
      endHourOptions[0].selected = true;
      self.endDateField().value = null;
    });
  },

  updateTimeLabel(fieldset, holder) {
    const startTimeSelect = this.startHourField();
    const startDate = this.startDateField();
    const startString = `${startTimeSelect.value}, ${startDate.value}`;

    let endString = "";
    const endTimeContent = this.endTimeContent(this.endTime(fieldset));
    if (nodeListToArray(endTimeContent.classList).includes("show")) {
      const endTime = this.endHourField().value;
      const endDate = this.endDateField().value;
      if (endTime && endDate) {
        endString = ` to ${endTime}, ${endDate}`;
      }
    }

    holder.textContent = `- ${startString}${endString}`;
  },

  endDateField() {
    return document.getElementById("event_end_date");
  },

  endHourField() {
    return document.getElementById("event_end_hour");
  },

  endTime(fieldset) {
    return fieldset.querySelector(`[data-js-toggle="end-time"]`);
  },

  endTimeContent(endTime) {
    const link = endTime.querySelector("[aria-controls]");
    return document.getElementById(link.getAttribute("aria-controls"));
  },

  startDateField() {
    return document.getElementById("event_start_date");
  },

  startHourField() {
    return document.getElementById("event_start_hour");
  },
};

ready(() => {
  eventTime.init();
});

export default eventTime;
