import { Collapse } from "bootstrap";

import idempotence from "../../idempotence";
import nodeListToArray from "../../nodeListToArray";
import ready from "../../ready";

const serviceSelector = {
  init() {
    const select = document.getElementById("service-selector");
    if (select) {
      if (idempotence.guard(select, "service-selector")) return;

      select.addEventListener("input", (event) => {
        nodeListToArray(
          select.parentNode.querySelectorAll(".collapse")
        ).forEach((collapse) => {
          const bsCollapse = Collapse.getInstance(collapse);
          if (bsCollapse) bsCollapse.hide();
        });
        const toShow = document.getElementById(
          `service-instructions-for-${event.target.value}`
        );
        if (toShow) {
          const bsShow = Collapse.getInstance(toShow) || new Collapse(toShow);
          bsShow.show();
        }
      });
    }
  },
};

ready(() => {
  serviceSelector.init();
});

export default serviceSelector;
