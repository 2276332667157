import authenticityToken from "./authenticityToken";

import executeScript from "../../executeScript";
import { json, status } from "../../fetchUtils";
import idempotence from "../../idempotence";
import nodeListToArray from "../../nodeListToArray";
import ready from "../../ready";

const selectTrigger = {
  init() {
    nodeListToArray(
      document.querySelectorAll("[data-js-select-trigger-path]")
    ).forEach((select) => {
      this.initItem(select);
    });
  },

  initItem(select) {
    if (idempotence.guard(select, "select-trigger")) return;

    const path = select.dataset.jsSelectTriggerPath;
    const method = select.dataset.jsSelectTriggerMethod;
    const field = select.dataset.jsSelectTriggerField;
    const type = select.dataset.jsSelectTriggerType;

    select.addEventListener("change", () => {
      if (type === "synchronous") {
        const parameters = window.location.search
          .replace("?", "")
          .split("&")
          .map((x) => x.split("="));
        let foundIt = false;
        const newParameters = [];
        parameters.forEach((x) => {
          if (x[0] === field) {
            newParameters.push([x[0], select.value]);
            foundIt = true;
          }
        });
        if (!foundIt) {
          newParameters.push([field, select.value]);
        }
        const search = newParameters.map((x) => x.join("=")).join("&");
        window.location.search = search;
      } else {
        const data = new FormData();
        data.append(field, select.value);
        data.append("_method", method);
        const token = authenticityToken();
        if (token) data.append("authenticity_token", token);
        fetch(path, {
          body: data,
          headers: { Accept: "application/json" },
          method: "POST",
        })
          .then(status)
          .then(json)
          .then((response) => {
            executeScript.execute(response.execute);
          });
      }
    });
  },
};

ready(() => {
  selectTrigger.init();
});

export default selectTrigger;
