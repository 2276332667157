import { json, status } from "../../fetchUtils";
import idempotence from "../../idempotence";
import nodeListToArray from "../../nodeListToArray";
import ready from "../../ready";

const postModalLoader = {
  init() {
    nodeListToArray(
      document.querySelectorAll("[data-js-load-post-modal]")
    ).forEach((loader) => {
      if (!idempotence.guard(loader, "load-post-modal")) {
        const url = new URL("/posts/load_modal", window.location.origin);
        url.searchParams.set("group_id", loader.dataset.jsGroupId);
        url.searchParams.set(
          "allowed_posting_types",
          JSON.parse(loader.dataset.jsAllowedPostingTypes)
        );
        fetch(url.toString(), { method: "GET" })
          .then(status)
          .then(json)
          .then((response) => {
            loader.innerHTML = response.component;
            FontAwesome.dom.i2svg(); // replace the icon tags with svgs
            // initialize all the stuff that appears in the form
            window.Alumnifire.addEmojiButton.init();
            window.Alumnifire.addMentionsButton.init();
            window.Alumnifire.tooltip.init();
            window.Alumnifire.autogrowTextarea.init();
            window.Alumnifire.characterCounter.init();
            window.Alumnifire.eventLocation.init();
            window.Alumnifire.eventTime.init();
            window.Alumnifire.placesAutocomplete.init();
            window.Alumnifire.previewImage.init();
            window.Alumnifire.requiredFieldset.init();
          });
      }
    });
  },
};

ready(() => {
  postModalLoader.init();
});

export default postModalLoader;
