import idempotence from "../../idempotence";
import nodeListToArray from "../../nodeListToArray";
import ready from "../../ready";

const autogrowTextarea = {
  init() {
    nodeListToArray(document.querySelectorAll("[data-js-autogrow]")).forEach(
      (autogrow) => {
        if (!idempotence.guard(autogrow, "autogrow-textarea")) {
          const textarea = autogrow.querySelector("textarea");
          textarea.addEventListener("input", () => {
            autogrow.dataset.replicatedValue = textarea.value;
            autogrow.setAttribute("data-replicated-value", textarea.value);
          });
        }
      }
    );
  },
};

ready(() => {
  autogrowTextarea.init();
});

export default autogrowTextarea;
