// for the moment, this is intended to work for only fieldsets of checkboxes
// we may extend the functionality later

import idempotence from "../../idempotence";
import nodeListToArray from "../../nodeListToArray";
import ready from "../../ready";

const requiredFieldset = {
  init() {
    nodeListToArray(document.querySelectorAll("fieldset[required]")).forEach(
      (fieldset) => {
        if (!idempotence.guard(fieldset, "required-fieldset")) {
          const checkboxes = fieldset.querySelectorAll(
            `input[type="checkbox"]`
          );
          const lastCheckbox = checkboxes[checkboxes.length - 1];
          // by default affix the error to the last checkbox in the list
          if (
            fieldset.querySelectorAll(`input[type="checkbox"]:checked`)
              .length === 0
          ) {
            lastCheckbox.setCustomValidity(
              "Please check at least one box if you want to proceed."
            );
          }
          nodeListToArray(checkboxes).forEach((checkbox) => {
            checkbox.addEventListener("input", () => {
              if (
                fieldset.querySelectorAll(`input[type="checkbox"]:checked`)
                  .length === 0
              ) {
                // after the user manipulates the form, affix the error to the last checkbox they touched
                checkbox.setCustomValidity(
                  "Please check at least one box if you want to proceed."
                );
              } else {
                nodeListToArray(checkboxes).forEach(
                  (cb) =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    cb.setCustomValidity("")
                  // eslint-disable-next-line function-paren-newline
                );
              }
            });
          });
        }
      }
    );
  },
};

ready(() => {
  requiredFieldset.init();
});

export default requiredFieldset;
